import type { MhluSectionTemplateData } from '@hubcms/domain-section';
import type { TeaserData, TeaserPropsOptions } from '@hubcms/domain-teaser';
import { TeaserGrid, TeaserGridExtensionsProvider } from '@hubcms/ui-teaser-grid';

import { sectionGridExtensions } from './sectionGridExtensions';
import { useSectionPageGroup } from './useSectionPageGroup';

type MHLUSectionOpeningProps = {
  templateData: MhluSectionTemplateData;
  articles: TeaserData[];
  teaserPropsOptions: TeaserPropsOptions;
};

function MHLUSectionOpening({ templateData, articles, teaserPropsOptions }: MHLUSectionOpeningProps) {
  const teaserGridProps = useSectionPageGroup(
    templateData.groupTitle,
    templateData.asideRecirculationStoryblocks,
    {
      groupArea: articles,
    },
    teaserPropsOptions,
  );

  return (
    <TeaserGridExtensionsProvider initialExtensions={sectionGridExtensions}>
      <TeaserGrid {...teaserGridProps} />
    </TeaserGridExtensionsProvider>
  );
}

export default MHLUSectionOpening;
