import { Heading } from '@mediahuis/chameleon-react';
import { useMemo } from 'react';

import type { MhluSectionTemplateData, SectionData } from '@hubcms/domain-section';
import { type TeaserPropsOptions, isArticleTeaserData, isNonTeaserList } from '@hubcms/domain-teaser';
import { Ad } from '@hubcms/feature-ads';
import { StoryblockList } from '@hubcms/feature-storyblock';
import { Container } from '@hubcms/ui-container';
import { useAdPositions } from '@hubcms/utils-ads';
import { deduplicateObjectsByKey, partitionArray } from '@hubcms/utils-browser';
import { usePager } from '@hubcms/utils-pagination';
import { flattenArticleLists } from '@hubcms/utils-teaser';

import { InfiniteArticleList } from '../../InfiniteArticleList';

import MHLUSectionOpening from './MHLUSectionOpening';
import styles from './section.module.scss';

type MHLUSectionProps = {
  sectionData: SectionData;
  templateData: MhluSectionTemplateData;
  teaserPropsOptions: TeaserPropsOptions;
};

export default function MHLUSection({ sectionData, templateData, teaserPropsOptions }: MHLUSectionProps) {
  const { pagedArticlesData } = sectionData;
  const {
    items: articles,
    isLoading,
    loadPage,
    hasMore,
  } = usePager({
    pageSize: pagedArticlesData.pageSize,
    initialItems: pagedArticlesData.initialItems,
    initialAmount: pagedArticlesData.initialAmount,
    initialHasMore: pagedArticlesData.initialHasMore,
    secondPageSearchParams: pagedArticlesData.secondPageSearchParams,
    getPageDataFromResponse: pagedArticlesData.getPageDataFromResponse,
    getNextPageSearchParams: pagedArticlesData.getNextPageSearchParams,
  });

  const news = flattenArticleLists(templateData.deskedTeaserData).filter(isNonTeaserList).filter(isArticleTeaserData);

  // Filter out duplications
  const deduplicatedArticles = useMemo(() => deduplicateObjectsByKey([...news, ...articles], 'id'), [news, articles]);

  // Slice some articles for the opening block
  const [templateArticles, infiniteScrollArticles] = useMemo(() => {
    return partitionArray(deduplicatedArticles, (_, idx) => idx < 3);
  }, [deduplicatedArticles]);

  const { getAdFormatForPage } = useAdPositions(pagedArticlesData.adPageSkip, pagedArticlesData.adFormats);

  if (sectionData.noContentMessage) {
    return (
      <Container>
        <Heading size="xl" level={4}>
          {sectionData.noContentMessage}
        </Heading>
      </Container>
    );
  }

  return (
    <>
      {sectionData.storyblocks.length > 0 && (
        <StoryblockList storyblocks={sectionData.storyblocks} teaserPropsOptions={teaserPropsOptions} />
      )}
      <div className={styles.mySectionContainer}>
        <Container fullWidthSm fullWidthMd>
          <MHLUSectionOpening templateData={templateData} articles={templateArticles} teaserPropsOptions={teaserPropsOptions} />
        </Container>
        {sectionData.templateDividerAdFormat && <Ad adFormat={sectionData.templateDividerAdFormat} adSlot="b" />}
        <Container fullWidthSm fullWidthMd>
          <InfiniteArticleList
            teaserHasDateTime={pagedArticlesData.teaserHasDateTime}
            articles={infiniteScrollArticles}
            pageSize={pagedArticlesData.pageSize}
            initialPageSize={pagedArticlesData.initialAmount}
            isLoading={isLoading}
            hasMorePages={hasMore}
            getAdFormatForPage={getAdFormatForPage}
            loadMore={loadPage}
            asideRecirculationStoryblocks={pagedArticlesData.asideRecirculationStoryblocks}
            teaserPropsOptions={teaserPropsOptions}
          />
        </Container>
      </div>
    </>
  );
}
