import { Ad } from '../Ad/Ad';

import styles from './pipe-ad.module.scss';

type PipeAdProps = {
  adFormat: string;
  adSlotPrefix: string;
};

export function PipeAd({ adFormat, adSlotPrefix }: PipeAdProps) {
  return (
    <Ad
      adFormat={adFormat}
      adSlot="b"
      adSlotPrefix={adSlotPrefix}
      isSticky
      adStickyTop="var(--scale-6)"
      className={styles.pipeAd}
    />
  );
}
