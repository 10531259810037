import type { SectionParams } from '@hubcms/domain-cook';
import { type PagedArticlesData, createPagedArticlesData } from '@hubcms/domain-section';
import type { ArticleTeaserData } from '@hubcms/domain-teaser';

type BuildOptions = {
  initialItems: ArticleTeaserData[];
  initialPageSizeKey: string;
  extraPageSizeKey: string;
  total?: number;
  adFormatsKey: string;
  adPageSkipSequenceKey: string;
  canShowEmptyListAd: boolean;
};

export function buildPagination<PaginationResponse>(
  sectionParams: SectionParams,
  options: BuildOptions,
): PagedArticlesData<PaginationResponse> {
  const { total = 0, initialItems, initialPageSizeKey, extraPageSizeKey, adFormatsKey, adPageSkipSequenceKey } = options;
  const canShowAds = sectionParams['b2b.kaching.blockads'] !== 'true';
  const initialAmount = Number(sectionParams[initialPageSizeKey]) || 1;
  const pageSize = Number(sectionParams[extraPageSizeKey]) || 8;

  const canShowEmptyListAd = canShowAds && options.canShowEmptyListAd;
  const { adFormats, adPageSkip } = canShowAds
    ? getAdFormatsAndPageSkip(sectionParams[adFormatsKey], sectionParams[adPageSkipSequenceKey])
    : { adFormats: [], adPageSkip: [] };

  return createPagedArticlesData<PaginationResponse>({
    initialItems,
    initialAmount,
    pageSize,
    initialHasMore: total > initialItems.length,
    adFormats,
    adPageSkip,
    canShowEmptyListAd,
  });
}

function getAdFormatsAndPageSkip(adFormatsParam: string | undefined, adPageSkipSequenceParam: string | undefined) {
  const adFormats = adFormatsParam?.split(',').map(format => format.trim()) || [];
  const adPageSkip =
    adPageSkipSequenceParam
      ?.split(',')
      .map(Number)
      .filter(n => !isNaN(n)) || [];
  if (adPageSkip.length === 0) {
    adPageSkip.push(0);
  }
  return {
    adFormats,
    adPageSkip,
  };
}
