import { useState, useEffect, useRef } from 'react';
import { useResizeObserver } from 'usehooks-ts';

type Size = {
  width: number | undefined;
  height: number | undefined;
};

export const LEADERBOARD_OBSERVE_TARGET_SELECTOR = 'leaderboard-observe-target';

export const useLeaderboardResizeObserver = (): Size => {
  const [size, setSize] = useState<Size>({ width: 0, height: 0 });
  const elementRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const element = document.querySelector(`#${LEADERBOARD_OBSERVE_TARGET_SELECTOR}`);
    if (element instanceof HTMLElement) {
      elementRef.current = element;
    }
  }, []);

  const onResize = ({ width, height }: Size) => {
    setSize({ width: width ?? 0, height: height ?? 0 });
  };

  useResizeObserver({
    ref: elementRef,
    onResize,
    box: 'border-box',
  });

  return size;
};
