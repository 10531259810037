import dynamic from 'next/dynamic';

import { AdServer } from './Ad.server';
import { AdPropsContext } from './ad-context';
import type { AdProps } from './types';

export type { AdProps };

const AdClient = dynamic(() => import('./Ad.client').then(m => m.Ad), {
  ssr: false,
  loading: () => <AdServer />,
});

export function Ad(props: AdProps) {
  return (
    <AdPropsContext.Provider value={props}>
      <AdClient {...props} />
    </AdPropsContext.Provider>
  );
}
