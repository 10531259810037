import { useMemo } from 'react';

import type { AuthorProfileTemplateData, SectionData } from '@hubcms/domain-section';
import type { TeaserPropsOptions } from '@hubcms/domain-teaser';
import { Ad } from '@hubcms/feature-ads';
import { Container } from '@hubcms/ui-container';
import { useAdPositions } from '@hubcms/utils-ads';
import { deduplicateObjectsByKey } from '@hubcms/utils-browser';
import { usePager } from '@hubcms/utils-pagination';

import { InfiniteArticleList } from '../InfiniteArticleList';

import { AuthorHeader } from './AuthorHeader';
import styles from './author-page.module.scss';

type AuthorPageProps = {
  sectionData: SectionData;
  templateData: AuthorProfileTemplateData;
  teaserPropsOptions: TeaserPropsOptions;
};

export default function AuthorPage({ sectionData, templateData, teaserPropsOptions }: AuthorPageProps) {
  const { headShotImageUrl, ...personalData } = templateData;

  const { pagedArticlesData } = sectionData;
  const {
    items: articles,
    isLoading,
    loadPage,
    hasMore,
  } = usePager({
    pageSize: pagedArticlesData.pageSize,
    initialItems: pagedArticlesData.initialItems,
    initialAmount: pagedArticlesData.initialAmount,
    initialHasMore: pagedArticlesData.initialHasMore,
    secondPageSearchParams: pagedArticlesData.secondPageSearchParams,
    getPageDataFromResponse: pagedArticlesData.getPageDataFromResponse,
    getNextPageSearchParams: pagedArticlesData.getNextPageSearchParams,
  });

  // Filter out duplications
  const deduplicatedArticles = useMemo(() => deduplicateObjectsByKey(articles, 'id'), [articles]);

  const { getAdFormatForPage } = useAdPositions(pagedArticlesData.adPageSkip, pagedArticlesData.adFormats);
  const emptyListAdFormat =
    pagedArticlesData.canShowEmptyListAd && deduplicatedArticles.length === 0 ? getAdFormatForPage(0) : null;

  return (
    <Container className={styles.authorPage}>
      {/* empty div in case the ad is hidden and the gap between the header and the author image is maintained */}
      <AuthorHeader personalData={personalData} headshotImage={headShotImageUrl} />
      <InfiniteArticleList
        teaserHasDateTime={pagedArticlesData.teaserHasDateTime}
        loadMore={loadPage}
        getAdFormatForPage={getAdFormatForPage}
        articles={deduplicatedArticles}
        isLoading={isLoading}
        hasMorePages={hasMore}
        pageSize={pagedArticlesData.pageSize}
        initialPageSize={pagedArticlesData.initialAmount}
        teaserPropsOptions={teaserPropsOptions}
      />
      {emptyListAdFormat && <Ad adFormat={emptyListAdFormat} adSlot="b" />}
    </Container>
  );
}
