import {
  type CookData,
  isAuthorProfilePage,
  isMhluHomePageContext,
  isMhluSectionPage,
  isMostRecentPage,
  isMySectionPage,
  isSearchPage,
  isTagPage,
} from '@hubcms/domain-cook';
import type { TeaserPropsOptions } from '@hubcms/domain-teaser';

import type { MapSectionPageReturnType } from '../domain/map-section-page-return-type';

import { createAuthorProfilePage } from './section-pages/createAuthorProfilePage';
import { createMhluHomePage } from './section-pages/createMhluHomePage';
import { createMhluSectionPage } from './section-pages/createMhluSectionPage';
import { createMostRecentPage } from './section-pages/createMostRecentPage';
import { createMySectionPage } from './section-pages/createMySectionPage';
import { createSearchPage } from './section-pages/createSearchPage';
import { createTagPage } from './section-pages/createTagPage';

export function mapSectionPage(cookData: CookData, teaserPropsOptions: TeaserPropsOptions): MapSectionPageReturnType {
  const canShowAds = cookData.sectionParams['b2b.kaching.blockads'] !== 'true';
  if (isMySectionPage(cookData)) {
    return createMySectionPage(cookData, teaserPropsOptions);
  }

  if (isTagPage(cookData)) {
    return createTagPage(cookData, teaserPropsOptions, canShowAds);
  }

  if (isSearchPage(cookData)) {
    return createSearchPage(cookData, teaserPropsOptions, canShowAds);
  }

  if (isAuthorProfilePage(cookData)) {
    return createAuthorProfilePage(cookData, teaserPropsOptions, canShowAds);
  }

  if (isMostRecentPage(cookData)) {
    return createMostRecentPage(cookData, teaserPropsOptions);
  }

  if (isMhluSectionPage(cookData)) {
    return createMhluSectionPage(cookData, teaserPropsOptions, canShowAds);
  }

  if (isMhluHomePageContext(cookData.context)) {
    return createMhluHomePage(cookData, teaserPropsOptions, canShowAds);
  }

  return null;
}
