import type { TStoryblock } from '@hubcms/domain-storyblock';
import type { TeaserPropsOptions } from '@hubcms/domain-teaser';
import type { TTeaserGridItem, TeaserGridExtensions } from '@hubcms/domain-teaser-grid';

import { AsideRecirculation } from '../../AsideRecirculation';

export type GSectionAsideRecirculation = TTeaserGridItem<
  'sectionAsideRecirculation',
  {
    asideRecirculationStoryblocks: TStoryblock[];
    teaserPropsOptions: TeaserPropsOptions;
  }
>;

export const sectionGridExtensions: TeaserGridExtensions = {
  sectionAsideRecirculation: (item: GSectionAsideRecirculation) => ({
    Component: AsideRecirculation,
    key: 'section-aside-recirculation',
    componentProps: {
      storyblocks: item.data.asideRecirculationStoryblocks,
      teaserPropsOptions: item.data.teaserPropsOptions,
    },
  }),
};
