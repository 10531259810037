import type { ArticleTeaserData } from '@hubcms/domain-teaser';

import type { ListPageResultTeaserProps } from './types';

export function getListPageResultTeaserProps(
  teaserData: ArticleTeaserData,
  hasIntro?: boolean,
  hasDateTime?: boolean,
  isSponsored = false,
): ListPageResultTeaserProps {
  const { url: link, ...props } = teaserData;

  const listPageResultTeaserProps: ListPageResultTeaserProps = {
    ...props,
    link,
    hasIntro: { xs: false, sm: hasIntro, md: hasIntro, lg: hasIntro, xl: hasIntro },
    hasInset: { xs: true, lg: isSponsored },
    hasDateTime,
    isHighlighted: isSponsored,
  };
  return listPageResultTeaserProps;
}
