import type { StoryElement } from './story-element';

export type AudioVisualData = {
  articleId: string;
  agnoPlayerBrand: string;
  agnoPlayerCdnId: string;
  agnoPlayerTheme: 'slim' | 'advanced';
  autoplayOnView: boolean;
  caption?: string;
  captionAlignment: 'left' | 'center' | 'right';
  credit?: string;
  hasAdvertisementsSkipped: boolean;
  clientId?: string;
};

export type AudioVisualStoryElement = StoryElement<'audiovisual', AudioVisualData>;

export function isAudioVisualElement(storyElement?: StoryElement | null): storyElement is AudioVisualStoryElement {
  return !!storyElement && storyElement.type === 'audiovisual';
}

export function createAudioVisualData({
  articleId = '',
  agnoPlayerBrand = '',
  agnoPlayerCdnId = '',
  agnoPlayerTheme = 'slim',
  autoplayOnView = false,
  caption = '',
  captionAlignment = 'left',
  credit = '',
  hasAdvertisementsSkipped = false,
  ...optionalProps
}: Partial<AudioVisualData> = {}): AudioVisualData {
  return {
    articleId,
    agnoPlayerBrand,
    agnoPlayerCdnId,
    agnoPlayerTheme,
    autoplayOnView,
    caption,
    captionAlignment,
    credit,
    hasAdvertisementsSkipped,
    ...optionalProps,
  };
}
