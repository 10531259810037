import { useMemo } from 'react';

import type { MostRecentTemplateData, SectionData } from '@hubcms/domain-section';
import type { TeaserPropsOptions } from '@hubcms/domain-teaser';
import { Ad } from '@hubcms/feature-ads';
import { Container } from '@hubcms/ui-container';
import { StoryHeader, StoryTitle } from '@hubcms/ui-story';
import { useAdPositions } from '@hubcms/utils-ads';
import { deduplicateObjectsByKey } from '@hubcms/utils-browser';
import { usePager } from '@hubcms/utils-pagination';
import { getThemeDataAttributes } from '@hubcms/utils-theme';

import { InfiniteArticleList } from '../InfiniteArticleList';

import styles from './mostrecent.module.scss';

type MostRecentProps = {
  sectionData: SectionData;
  templateData: MostRecentTemplateData;
  teaserPropsOptions: TeaserPropsOptions;
};

export default function MostRecentPage({
  sectionData: { pagedArticlesData, theme },
  templateData: { title },
  teaserPropsOptions,
}: MostRecentProps) {
  const {
    items: articles,
    isLoading,
    loadPage,
    hasMore,
  } = usePager({
    pageSize: pagedArticlesData.pageSize,
    initialItems: pagedArticlesData.initialItems,
    initialAmount: pagedArticlesData.initialAmount,
    initialHasMore: pagedArticlesData.initialHasMore,
    secondPageSearchParams: pagedArticlesData.secondPageSearchParams,
    getPageDataFromResponse: pagedArticlesData.getPageDataFromResponse,
    getNextPageSearchParams: pagedArticlesData.getNextPageSearchParams,
  });

  // Filter out duplications
  const deduplicatedArticles = useMemo(() => deduplicateObjectsByKey(articles, 'id'), [articles]);

  const { getAdFormatForPage } = useAdPositions(pagedArticlesData.adPageSkip, pagedArticlesData.adFormats);
  const emptyListAdFormat =
    pagedArticlesData.canShowEmptyListAd && deduplicatedArticles.length === 0 ? getAdFormatForPage(0) : null;

  const titleComponent = (
    <div className={styles.mainContent}>
      <StoryHeader className={styles.header}>
        <StoryTitle headingData={{ text: title, annotations: [] }} className={styles.heading} />
      </StoryHeader>
    </div>
  );

  return (
    <Container fullWidthSm fullWidthMd {...getThemeDataAttributes(theme)}>
      <InfiniteArticleList
        teaserHasDateTime={pagedArticlesData.teaserHasDateTime}
        loadMore={loadPage}
        prependChildren={titleComponent}
        getAdFormatForPage={getAdFormatForPage}
        articles={deduplicatedArticles}
        isLoading={isLoading}
        hasMorePages={hasMore}
        pageSize={pagedArticlesData.pageSize}
        initialPageSize={pagedArticlesData.initialAmount}
        teaserPropsOptions={teaserPropsOptions}
      />
      {emptyListAdFormat && <Ad adFormat={emptyListAdFormat} adSlot="b" />}
    </Container>
  );
}
