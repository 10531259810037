import { type CookData, isMhluHomePageContext } from '@hubcms/domain-cook';
import { createSectionData } from '@hubcms/domain-section';
import type { TeaserPropsOptions } from '@hubcms/domain-teaser';
import { getStoryblocks } from '@hubcms/utils-storyblock';

import type { MapSectionPageReturnType } from '../../domain/map-section-page-return-type';
import { getHomePageAdParameters } from '../../utils/getHomePageAdParameters';

export function createMhluHomePage(
  cookData: CookData,
  teaserPropsOptions: TeaserPropsOptions,
  canShowAds = true,
): MapSectionPageReturnType {
  if (!isMhluHomePageContext(cookData.context)) {
    return null;
  }
  const { sectionParams } = cookData;

  const sectionData = createSectionData();

  const areaNames = cookData.context.rootGroup.areaNames?.filter(name => name !== 'nonContent') || [];
  sectionData.storyblocks = getStoryblocks(cookData.context.rootGroup as never, {
    sectionParams,
    teaserPropsOptions,
    areaNames,
    hasNoAds: !canShowAds,
  });

  if (sectionData.storyblocks.length === 0) {
    sectionData.noContentMessage = sectionParams['section-tag.noresults'];
  }

  if (canShowAds) {
    sectionData.storyblockAds = getHomePageAdParameters(cookData.context.rootGroup.nonContent);
    sectionData.leadingAdFormat = 'leaderboard';
    sectionData.leftPipeAdFormat = sectionParams['b2b.leftpipe.adformat'];
    sectionData.rightPipeAdFormat = sectionParams['b2b.rightpipe.adformat'];
  }

  return {
    sectionData,
  };
}
