import { type CookData, type IndexPageSearchData, isSearchPage } from '@hubcms/domain-cook';
import { createSearchPageTemplateData, createSectionData } from '@hubcms/domain-section';
import type { TeaserPropsOptions } from '@hubcms/domain-teaser';
import { getIsTeaserDateShown } from '@hubcms/utils-teaser';

import type { MapSectionPageReturnType } from '../../domain/map-section-page-return-type';
import { buildPagination } from '../../utils/buildPagination';
import { getArticles } from '../../utils/getArticles';

export function createSearchPage(
  cookData: CookData,
  teaserPropsOptions: TeaserPropsOptions,
  canShowAds = true,
): MapSectionPageReturnType {
  if (!isSearchPage(cookData)) {
    return null;
  }
  const { sectionParams } = cookData;

  const pagedArticlesData = buildSearchPagedArticlesData(cookData, teaserPropsOptions, canShowAds);
  const sectionData = createSectionData({ pagedArticlesData });

  const urlSearchParams = new URLSearchParams(cookData.resolution.section.selfStr);
  let query = urlSearchParams.get('q');
  if (!query || query === 'undefined' || query === 'null') {
    query = '';
  }
  const templateData = createSearchPageTemplateData({
    query,
    sortBy: urlSearchParams.get('sort') ?? '',
    sectionUrl: cookData.resolution.section.href,
    resultCount: Number(cookData.resolution.section.resultCount) || 0,
    searchBoxLabel: sectionParams['searchlabel.textbox'],
    searchBoxPlaceholder: sectionParams['searchlabel.textbox'],
  });

  if (canShowAds) {
    sectionData.leadingAdFormat = sectionParams['b2b.leaderboard.adformat'];
  }

  return {
    sectionData,
    templateData,
  };
}

function buildSearchPagedArticlesData(
  cookData: CookData<IndexPageSearchData>,
  teaserPropsOptions: TeaserPropsOptions,
  canShowAds: boolean,
) {
  const { sectionParams } = cookData;

  const { results, nextPage } = getSearchPaginationData(cookData);
  const pagedArticlesData = buildPagination<IndexPageSearchData>(sectionParams, {
    initialItems: getArticles(results, teaserPropsOptions),
    initialPageSizeKey: 'section.pagination.webInitialAmount',
    extraPageSizeKey: 'section.pagination.webExtraAmount',
    total: Number(cookData.resolution.section.resultCount) || 0,
    adFormatsKey: 'b2b.section.default.ads.adformat',
    adPageSkipSequenceKey: 'b2b.section.default.ads.pageskip',
    canShowEmptyListAd: true,
  });

  pagedArticlesData.teaserHasDateTime = getIsTeaserDateShown('search');
  pagedArticlesData.secondPageSearchParams = nextPage;
  pagedArticlesData.initialHasMore =
    Number(cookData.resolution.section.resultCount) > Number(cookData.resolution.section.pageSize);

  pagedArticlesData.getNextPageSearchParams = responseData => {
    const nextData = getSearchPaginationData(responseData);
    return nextData.nextPage;
  };

  pagedArticlesData.getPageDataFromResponse = responseData => {
    const nextData = getSearchPaginationData(responseData);
    return {
      pageableItems: getArticles(responseData.results, teaserPropsOptions),
      hasMore: nextData.nextPage !== 'undefined',
    };
  };

  return pagedArticlesData;
}

function getSearchPaginationData({
  results,
  resolution: {
    section: { previousPage, nextPage },
  },
}: IndexPageSearchData) {
  return {
    results,
    previousPage,
    nextPage,
  };
}
