import cx from 'classnames';
import { type ReactNode, useMemo } from 'react';

import { searchTermQuotationMark } from '@hubcms/brand';
import { useCook } from '@hubcms/data-access-cook';
import { replaceTextSimple } from '@hubcms/utils-text';

import styles from './search-page.module.scss';

const noResultsKey = 'search.noresults';
const resultsKey = 'search.results';

type UseSearchResultMsgProps = {
  queryString: string;
  resultCount: number;
};

export function useSearchResultMsg({ queryString, resultCount }: UseSearchResultMsgProps): { resultsMsgNode: ReactNode } {
  const { sectionParams } = useCook();

  const sourceString = useMemo(() => {
    const { [noResultsKey]: noResultsString, [resultsKey]: resultsString } = sectionParams || {};
    return (isNaN(resultCount) || resultCount === 0 ? noResultsString : resultsString) ?? '';
  }, [resultCount, sectionParams]);

  const replacements = useMemo(
    () => ({
      '{query}': (
        <span key="queryString" className={cx(styles.resultMsgVar, styles.resultMsgKeyword)}>
          {`${searchTermQuotationMark ? '‘' : ''}${queryString}${searchTermQuotationMark ? '’' : ''}`}
        </span>
      ),
      '{amount}': (
        <span key="amount" className={styles.resultMsgVar}>
          {isNaN(resultCount) ? 0 : resultCount}
        </span>
      ),
    }),
    [queryString, resultCount],
  );

  const resultsMsgNode = useMemo(() => {
    return replaceTextSimple(sourceString, replacements);
  }, [sourceString, replacements]);

  return {
    resultsMsgNode,
  };
}
