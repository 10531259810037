import type { ExtendedDataSource } from '@hubcms/domain-cook';

import type { PageData } from '../domain/page-data';

export function getPageDataFromExtendedDataSource<PageableArrayItem>(
  extendedDataSource?: ExtendedDataSource<PageableArrayItem>,
): PageData<PageableArrayItem> {
  if (!extendedDataSource) {
    return {
      pageableItems: [],
      hasMore: false,
    };
  }
  return {
    pageableItems: extendedDataSource.items,
    hasMore: extendedDataSource.offset + extendedDataSource.count < extendedDataSource.total,
  };
}
