import { type AuthorProfileData, type CookData, isAuthorProfilePage } from '@hubcms/domain-cook';
import { createSectionData } from '@hubcms/domain-section';
import type { TeaserPropsOptions } from '@hubcms/domain-teaser';
import { getPageDataFromExtendedDataSource } from '@hubcms/utils-pagination';
import { getIsTeaserDateShown } from '@hubcms/utils-teaser';

import type { MapSectionPageReturnType } from '../../domain/map-section-page-return-type';
import { buildPagination } from '../../utils/buildPagination';
import { extractAuthorProfileFields } from '../../utils/extractAuthorProfileFields';
import { getArticles } from '../../utils/getArticles';

export function createAuthorProfilePage(
  cookData: CookData,
  teaserPropsOptions: TeaserPropsOptions,
  canShowAds = true,
): MapSectionPageReturnType {
  if (!isAuthorProfilePage(cookData)) {
    return null;
  }
  const { sectionParams } = cookData;

  const pagedArticlesData = buildAuthorProfilePagedArticleData(cookData, teaserPropsOptions);
  const sectionData = createSectionData({ pagedArticlesData });

  if (canShowAds && sectionParams['b2b.leaderboard.adformat']) {
    sectionData.leadingAdFormat = 'leaderboard';
  }

  const templateData = extractAuthorProfileFields(cookData.context);

  return {
    sectionData,
    templateData,
  };
}

function buildAuthorProfilePagedArticleData(cookData: CookData<AuthorProfileData>, teaserPropsOptions: TeaserPropsOptions) {
  const { sectionParams } = cookData;
  const pagedArticlesData = buildPagination<AuthorProfileData>(sectionParams, {
    initialItems: getArticles(cookData.articles?.items, teaserPropsOptions),
    initialPageSizeKey: 'section.pagination.webInitialAmount',
    extraPageSizeKey: 'section.pagination.webExtraAmount',
    total: cookData.articles?.total,
    adFormatsKey: 'b2b.section.default.ads.adformat',
    adPageSkipSequenceKey: 'b2b.section.default.ads.pageskip',
    canShowEmptyListAd: true,
  });
  pagedArticlesData.teaserHasDateTime = getIsTeaserDateShown('author-profile');
  pagedArticlesData.getPageDataFromResponse = responseData => {
    const pageData = getPageDataFromExtendedDataSource(responseData.articles);
    return {
      pageableItems: getArticles(pageData.pageableItems, teaserPropsOptions),
      hasMore: pageData.hasMore,
    };
  };

  return pagedArticlesData;
}
