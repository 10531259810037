import { Heading } from '@mediahuis/chameleon-react';
import cx from 'classnames';
import { useMemo } from 'react';

import type { SectionData, TagPageTemplateData } from '@hubcms/domain-section';
import type { TeaserPropsOptions } from '@hubcms/domain-teaser';
import { Ad } from '@hubcms/feature-ads';
import { Container } from '@hubcms/ui-container';
import { StoryHeader, StoryTitle } from '@hubcms/ui-story';
import { useAdPositions } from '@hubcms/utils-ads';
import { deduplicateObjectsByKey, partitionArray } from '@hubcms/utils-browser';
import { warn } from '@hubcms/utils-monitoring';
import { usePager } from '@hubcms/utils-pagination';
import { getThemeDataAttributes } from '@hubcms/utils-theme';

import { InfiniteArticleList } from '../InfiniteArticleList';

import TagPageTemplate from './TagPageTemplate';
import styles from './tagpage.module.scss';

type TagPageProps = {
  sectionData: SectionData;
  templateData: TagPageTemplateData;
  teaserPropsOptions: TeaserPropsOptions;
};

const TAG_INTRO_TEASER_AMOUNT = 3;

const TagPage = ({ sectionData, templateData, teaserPropsOptions }: TagPageProps) => {
  const { pagedArticlesData } = sectionData;
  const hasTagPageIntro = templateData.bodyElements.length > 0 || !!templateData.storyHeaderData.articleId;

  const {
    items: articles,
    isLoading,
    loadPage,
    hasMore,
  } = usePager({
    pageSize: pagedArticlesData.pageSize,
    initialItems: pagedArticlesData.initialItems,
    initialAmount: pagedArticlesData.initialAmount + TAG_INTRO_TEASER_AMOUNT,
    initialHasMore: pagedArticlesData.initialHasMore,
    secondPageSearchParams: pagedArticlesData.secondPageSearchParams,
    getPageDataFromResponse: pagedArticlesData.getPageDataFromResponse,
    getNextPageSearchParams: pagedArticlesData.getNextPageSearchParams,
  });

  // Filter out duplications
  const deduplicatedArticles = useMemo(() => deduplicateObjectsByKey(articles, 'id'), [articles]);

  // Slice some articles for the template
  const [templateArticles, infiniteScrollArticles] = useMemo(() => {
    const [b2bTagIntros, nonB2bTagIntros] = partitionArray(
      deduplicatedArticles,
      article => article.contentType === 'tagIntroB2B',
    );

    if (hasTagPageIntro) {
      return [
        nonB2bTagIntros.slice(0, TAG_INTRO_TEASER_AMOUNT),
        b2bTagIntros.concat(nonB2bTagIntros.slice(TAG_INTRO_TEASER_AMOUNT)),
      ];
    }

    return [[], [...b2bTagIntros, ...nonB2bTagIntros]];
  }, [deduplicatedArticles, hasTagPageIntro]);

  const { getAdFormatForPage } = useAdPositions(pagedArticlesData.adPageSkip, pagedArticlesData.adFormats);

  if (!pagedArticlesData.initialAmount) {
    warn('tagpage.pagination.webInitialAmount not set. will cause pagination issues.');
  }

  if (!pagedArticlesData.pageSize) {
    warn('tagpage size not specified; using default of 10.');
  }

  if (sectionData.noContentMessage) {
    return (
      <Container>
        <Heading className={styles.emptyHeading} size="xl" level={4}>
          {sectionData.noContentMessage}
        </Heading>
      </Container>
    );
  }

  const dividerAdFormat = sectionData.templateDividerAdFormat;
  const titleComponent = hasTagPageIntro ? (
    <div className={styles.mainContent}>
      <TagPageTemplate templateData={templateData} tagBlockArticles={templateArticles} />
      {dividerAdFormat && <Ad adFormat={dividerAdFormat} adSlot="b" className={styles.dividerAd} />}
    </div>
  ) : (
    <div className={styles.mainContent}>
      <StoryHeader className={styles.header}>
        <StoryTitle headingData={{ text: sectionData.title, annotations: [] }} />
      </StoryHeader>
    </div>
  );

  const getAdClassName = (index: number) => {
    return index + 1 === pagedArticlesData.pageSize
      ? cx({
          [styles.headingSideAdContainer]: templateData.storyHeaderData,
        })
      : '';
  };

  return (
    <Container fullWidthSm fullWidthMd {...getThemeDataAttributes(sectionData.theme)}>
      <InfiniteArticleList
        getAdClassName={getAdClassName}
        prependChildren={titleComponent}
        articles={infiniteScrollArticles}
        teaserHasDateTime={pagedArticlesData.teaserHasDateTime}
        pageSize={pagedArticlesData.pageSize}
        initialPageSize={pagedArticlesData.initialAmount}
        isLoading={isLoading}
        hasMorePages={hasMore}
        getAdFormatForPage={getAdFormatForPage}
        loadMore={loadPage}
        className={styles.content}
        teaserPropsOptions={teaserPropsOptions}
      />
    </Container>
  );
};
export default TagPage;
